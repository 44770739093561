export function getBirthdate(nric: string): string {
  let birthdate = "";
  if (nric.length === 12) {
    const year = nric.substring(0, 2);
    const month = nric.substring(2, 4);
    const day = nric.substring(4, 6);

    const currentYear = new Date().getFullYear().toString().substring(2);
    const prefix = parseInt(year) > parseInt(currentYear) ? "19" : "20";

    birthdate = `${prefix}${year}-${month}-${day}`;
  }
  return birthdate;
}

export function getGender(nric: string): string {
  let gender = "";
  if (nric.length === 12) {
    const genNo = parseInt(nric.substring(10, 12));
    gender = genNo % 2 === 0 ? "Female" : "Male";
  }
  return gender;
}

// Assuming getBirthdate is already defined as per your provided function

export function getAge(nric: string): number {
  const birthdateStr = getBirthdate(nric);
  if (!birthdateStr) {
    return 0; // Or handle invalid NRIC/birthdate differently
  }

  const birthdate = new Date(birthdateStr);
  const today = new Date();
  let age = today.getFullYear() - birthdate.getFullYear();
  const monthDifference = today.getMonth() - birthdate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthdate.getDate())
  ) {
    age--; // Decrease age if the birthday hasn't occurred yet this year
  }

  return age;
}

export const defaultUserRawProfile = {
  id: null,
  user_id: null,
  type: "profile",
  raw: {
    address1: "",
    address2: "",
    address3: "",
    alt_phone: "",
    birthdate: "",
    city: "",
    education: "",
    email: "",
    employment: "",
    employment_address: "",
    employment_name: "",
    gender: "",
    have_living_trust_setup: false,
    have_power_of_attorney: false,
    have_testamentary_trust_setup: false,
    id: null,
    is_good_health: false,
    is_muslim: false,
    is_smoker: false,
    life_expectancy: "",
    marital_status: "",
    medical_hereditary: "",
    name: "",
    name_of_attorney: "",
    no_of_dependent: "",
    nric: "",
    occupation: "",
    phone: "",
    postcode: "",
    race: "",
    residential_status: "",
    retirement_age: 0,
    retirement_date: "",
    state: "",
    will_held_by: "",
    will_last_updated: "",
  },
};

export const defaultProfile = {
  id: null,
  name: "",
  nric: "",
  email: "",
  gender: "",
  birthdate: "",
  phone: "",
  created_at: "",
  updated_at: "",
  domain: null,
  enabled: 1,
  user_raw_profile: {...defaultUserRawProfile},
  user_raw_spouses: {
    id: null,
    user_id: null,
    type: "spouses",
    raw: [
      {
        name: "",
        nric: "",
        email: "",
        phone: "",
        employment: "",
      },
    ],
  },
};

export const defaultParent = {
  name: "",
  nric: "",
  email: "",
  phone: "",
  race: "",
  address1: "",
  address2: "",
  address3: "",
  state: "",
  city: "",
  postcode: "",
  percentage: "",
  special_bequest: "",
  receive_portion: false,
  entire_estate: false,
};

export const defaultChild = {
  name: "",
  nric: "",
  birthdate: "",
  is_under_18: false,
  is_oku: false,
  guardian_name: "",
  gender: "",
  percentage: "",
  receive_portion: false,
};

export const defaultSibling = {
  name: "",
  nric: "",
  email: "",
  phone: "",
  gender: "",
  address_1: "",
  address_2: "",
  address_3: "",
  state: "",
  city: "",
  postcode: "",
  percentage: "",
  special_bequest: "",
  receive_portion: false,
  entire_estate: false,
  spouse_name: "",
};

export const defaultOther = {
  is_individual: false,
  name: "",
  nric: "",
  organisation_name: "",
  organisation_reg_no: "",
  email: "",
  phone: "",
  address_1: "",
  address_2: "",
  address_3: "",
  state: "",
  city: "",
  postcode: "",
  percentage: "",
  special_bequest: "",
  receive_portion: false,
  entire_estate: true,
};

export const defaultExecutor = {
  name: "",
  nric: "",
  email: "",
  phone: "",
  address_1: "",
  address_2: "",
  address_3: "",
  state: "",
  city: "",
  postcode: "",
};

export const defaultExecutorWithEnable = {
  ...defaultExecutor,
  is_enable: false,
};

export const defaultExecutors = {
  executor_1: { ...defaultExecutor },
  executor_2: { ...defaultExecutorWithEnable },
  is_compensated: false,
};

export const defaultTrustee = {
  name: "",
  nric: "",
  email: "",
  phone: "",
  address_1: "",
  address_2: "",
  address_3: "",
  state: "",
  city: "",
  postcode: "",
};

export const default2ndTrustee = {
  ...defaultTrustee,
  is_enable: false,
};

export const defaultTrustees = {
  trustee_1: { ...defaultTrustee },
  trustee_2: { ...default2ndTrustee },
  resignation_notice: "60",
};

export const defaultDistribution = {
  spouses: [],
  children: [],
  parents: {
    father: { ...defaultParent },
    mother: { ...defaultParent },
  },
  siblings: [],
  others: [],
}
