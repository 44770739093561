
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import WillPlanningSummaryModal from "./WillPlanningSummaryModal.vue";
import {
  defaultProfile,
  defaultParent,
  defaultExecutors,
  defaultTrustees,
} from "../../../utils/ProfileUtils";
export default defineComponent({
  components: {
    WillPlanningSummaryModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("View Will Planning", [
        "Financial Planning",
        "Will Planning",
      ]);
    });
  },
  data() {
    const route = useRoute();
    const store = useStore();

    const profile = defaultProfile;
    const spouses = [];
    const children = [];
    const parents = defaultParent;
    const siblings = [];
    const others = [];
    const executors = defaultExecutors;
    const trustees = defaultTrustees;
    const distribution = [];
    const special_request = {
      is_enable: false,
      details: "",
    };
    const personal_belongings = {
      details: "",
    };
    const residuary_estate = {
      details: [],
    };
    const willPlanning = {} as any;

    return {
      cstmr_id: route.params.id ?? null,
      will_id: route.params.will_id ?? null,
      store,
      profile,
      spouses,
      children,
      parents,
      siblings,
      others,
      executors,
      trustees,
      distribution,
      special_request,
      personal_belongings,
      residuary_estate,
      willPlanning,
    };
  },
  methods: {
    init() {
      if (this.cstmr_id) {
        this.showSwalLoading("Populating Data");
        this.store
          .dispatch(Actions.GET_WILL_PLANNING, this.will_id)
          .then(() => {
            this.willPlanning = this.store.getters.getWillPlanningData.raw;
            this.profile = this.willPlanning.profile;
            this.spouses = this.willPlanning.spouses;
            this.children = this.willPlanning.children;
            this.parents = this.willPlanning.parents;
            this.siblings = this.willPlanning.siblings;
            this.others = this.willPlanning.others;
            this.executors = this.willPlanning.executors;
            this.trustees = this.willPlanning.trustees;
            this.distribution = this.willPlanning.distribution;
            this.special_request = this.willPlanning.special_request;
            this.personal_belongings = this.willPlanning.personal_belongings;
            this.residuary_estate = this.willPlanning.residuary_estate;
            Swal.close();
          });
      }
    },
    showSwalLoading(message) {
      Swal.fire({
        title: message,
        text: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    },
  },
  async mounted() {
    this.init();
  },
});
