
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import {
  defaultProfile,
  defaultParent,
  defaultExecutors,
  defaultTrustees,
  defaultDistribution,
} from "../../../utils/ProfileUtils";

export default defineComponent({
  props: [
    "theProfile",
    "theSpouses",
    "theChildren",
    "theParents",
    "theSiblings",
    "theOthers",
    "theExecutors",
    "theTrustees",
    "theDistribution",
    "theSpecialRequest",
    "thePersonalBelongings",
    "theResiduaryEstate",
    "isMuslim",
  ],
  data() {
    return {
      profile: { ...defaultProfile, ...this.theProfile },
      spouses: this.theSpouses,
      children: this.theChildren,
      parents: {...defaultParent,...this.theParents},
      siblings: this.theSiblings,
      others: this.theOthers,
      executors: this.theExecutors,
      trustees: this.theTrustees,
      distribution: {...defaultDistribution,...this.theDistribution},
      special_request: this.theSpecialRequest,
      personal_belongings: this.thePersonalBelongings,
      residuary_estate: this.theResiduaryEstate,
    };
  },
  methods: {
    numberToOrdinal(n) {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;

      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
  },
  async mounted() {
    // this.init();
    console.log("distribution", this.distribution);
  },
});
