import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WillPlanningSummaryModal = _resolveComponent("WillPlanningSummaryModal")!

  return (_openBlock(), _createBlock(_component_WillPlanningSummaryModal, {
    profile: _ctx.profile,
    spouses: _ctx.spouses,
    children: _ctx.children,
    parents: _ctx.parents,
    siblings: _ctx.siblings,
    others: _ctx.others,
    executors: _ctx.executors,
    trustees: _ctx.trustees,
    distribution: _ctx.distribution,
    special_request: _ctx.special_request,
    personal_belongings: _ctx.personal_belongings,
    residuary_estate: _ctx.residuary_estate
  }, null, 8, ["profile", "spouses", "children", "parents", "siblings", "others", "executors", "trustees", "distribution", "special_request", "personal_belongings", "residuary_estate"]))
}